// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Data
export const cantons = [
  "Vaud",
  "Zürich",
  "Bern",
  "Uri",
  "Schwyz",
  "Glarus",
  "Zug",
  "Fribourg",
  "Solothurn",
  "Basel-Stadt",
  "Basel-Landschaft",
  "Schaffhausen",
  "Appenzell Ausserrhoden",
  "Appenzell Innerrhoden",
  "St. Gallen",
  "Graubünden/Grigioni",
  "Aargau",
  "Thurgau",
  "Ticino",
  "Valais",
  "Neuchâtel",
  "Genève",
  "Jura",
];

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const AUTH_LOAD_USER_REQUEST = "AUTH_LOAD_USER_REQUEST";
export const AUTH_LOAD_USER_SUCCESS = "AUTH_LOAD_USER_SUCCESS";
export const AUTH_LOAD_USER_FAILURE = "AUTH_LOAD_USER_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_ADD_USER_REQUEST = "AUTH_ADD_USER_REQUEST";
export const AUTH_ADD_USER_SUCCESS = "AUTH_ADD_USER_SUCCESS";
export const AUTH_ADD_USER_FAILURE = "AUTH_ADD_USER_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// Auth
export const FETCH_MY_CALLS_REQUEST = "FETCH_MY_CALLS_REQUEST";
export const FETCH_MY_CALLS_SUCCESS = "FETCH_MY_CALLS_SUCCESS";
export const FETCH_MY_CALLS_FAILURE = "FETCH_MY_CALLS_FAILURE";
export const UPDATE_A_CALLS_SUCCESS = "UPDATE_A_CALLS_SUCCESS";
export const ADD_A_CALL_SUCCESS = "ADD_A_CALL_SUCCESS";

// Form
export const FICHE_D_INFORMATIONS_REQUEST = "FICHE_D_INFORMATIONS_REQUEST";
export const FICHE_D_INFORMATIONS_SUCCESS = "FICHE_D_INFORMATIONS_SUCCESS";
export const FICHE_D_INFORMATIONS_FAILURE = "FICHE_D_INFORMATIONS_FAILURE";

// Property
export const PROPERTY_FETCH_REQUEST = "PROPERTY_FETCH_REQUEST";
export const PROPERTY_FETCH_SUCCESS = "PROPERTY_FETCH_SUCCESS";
export const PROPERTY_FETCH_FAILURE = "PROPERTY_FETCH_FAILURE";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const SENT_MESSAGE_SUCCESS = "SENT_MESSAGE_SUCCESS";
export const PROPERTY_STATUS_UPDATE_SUCCESS = "PROPERTY_STATUS_UPDATE_SUCCESS";
export const PROPERTY_UPDATE_SUCCESS = "PROPERTY_UPDATE_SUCCESS";
export const PROPERTY_CREATE_SUCCESS = "PROPERTY_CREATE_SUCCESS";
export const PROPERTY_DELETE_SUCCESS = "PROPERTY_DELETE_SUCCESS";

// Employees
export const EMPLOYEES_FETCH_REQUEST = "EMPLOYEES_FETCH_REQUEST";
export const EMPLOYEES_FETCH_SUCCESS = "EMPLOYEES_FETCH_SUCCESS";
export const EMPLOYEES_FETCH_FAILURE = "EMPLOYEES_FETCH_FAILURE";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
