import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import { ficheDInformations, sendNewPropertyEmail } from "../../redux/actions/propertyActions";

import {
  Button,
  CircularProgress,
  Paper,
  TextField as MuiTextField,
  Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import {
  getDefaultFicheDInformations,
  ValidateFicheDInformations,
} from "../../utils/validation";
import { PropertyForm } from "../components/PropertyForm";
import { validateFicheDInformationsToken } from "../../services/propertyService";
import Loader from "../../components/Loader";

const Alert = styled(MuiAlert)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const Logo = styled((props) => <img {...props} />)`
  height: 92px;
  text-align: center;
  display: block;
  margin: 0 auto ${(props) => props.theme.spacing(5)}px;
`;

function FicheDInformations() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { quoteToken } = useParams();

  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [clientMailSent, setClientMailSent] = useState(false);

  useEffect(() => {
    validateFicheDInformationsToken(quoteToken)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        setError(error);
      });
  }, []);

  const initialValues = useMemo(() => {
    return getDefaultFicheDInformations(quoteToken);
  }, [completed, quoteToken]);

  return (
    <Wrapper>
      <Helmet title="Fiche d´Informations" />
      <Logo alt="Noviac" src="/static/img/logos/blue.png" />

      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Fiche d´Informations
      </Typography>
      {loading ? (
        <Loader />
      ) : error ? (
        <div>
          <Alert mt={2} mb={1} severity="info">
            Cette URL a déjà été utilisée.
          </Alert>
          <Button
            fullWidth
            variant="text"
            color="primary"
            onClick={() => history.replace("/")}
          >
            Close app
          </Button>
        </div>
      ) : completed ? (
        <div>
          <Alert mt={2} mb={1} severity="info">
            Merci d'avoir choisi Noviac Immobilier. Nous avons bien reçu vos
            informations. Un agent reviendra vers vous dans les meilleurs
            délais.
          </Alert>
          <Button
            fullWidth
            variant="text"
            color="primary"
            onClick={() => history.replace("/")}
          >
            Close app
          </Button>
        </div>
      ) : (
        <PropertyForm
          initialValues={initialValues}
          validationSchema={ValidateFicheDInformations}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if(values.active_step == 1)
              {
                if(!clientMailSent)
                {
                  const data = await dispatch(sendNewPropertyEmail({ ...values, quoteToken }));
                  if (data.active_step == 1) {
                    setClientMailSent(true);
                    setActiveStep(values.active_step);
                  }
                }
                else
                {
                  setActiveStep(values.active_step);
                }
              }
              else if (values.active_step == 2) {
              setActiveStep(values.active_step);
                const data = await dispatch(
                  ficheDInformations({ ...values, quoteToken })
                );
                setCompleted(data);
              }
            } catch (error) {
              const message = error.message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
          activeStep={activeStep}
                setActiveStep={setActiveStep}
                hideTitle="Please hide the title"
        />
      )}
    </Wrapper>
  );
}

export default FicheDInformations;
