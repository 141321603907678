import React from "react";

import async from "../components/Async";

import {
  Calendar as CalendarIcon,
  CheckSquare,
  CreditCard,
  Info,
  Monitor,
  MessageSquare,
  Phone,
  User,
  Sliders,
  Settings as SettingsIcon,
  Users as UsersIcon,
  PenTool as PenToolIcon,
  BookOpen as BookOpenIcon,
  Briefcase as BriefcaseIcon,
  Folder,
} from "react-feather";

import { Domain, DomainDisabled } from "@material-ui/icons";
import { Redirect } from "react-router-dom";
import FicheDInformations from "../pages/pages/FicheDInformations";

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));
const GuestGuard = async(() => import("../components/GuestGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
// const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

const Dashboard = async(() => import("../pages/pages/dashboard/"));

// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
const InvoiceDetails = async(() => import("../pages/pages/InvoiceDetails"));
const InvoiceList = async(() => import("../pages/pages/InvoiceList"));
const Calls = async(() => import("../pages/pages/noviac/calls/Calls"));
const AddCalls = async(() => import("../pages/pages/noviac/calls/AddCalls"));
const Users = async(() => import("../pages/pages/noviac/users/Users"));
const AddUser = async(() => import("../pages/pages/noviac/users/AddUser"));
const Profile = async(() => import("../pages/pages/noviac/users/UserProfile"));
const Settings = async(() => import("../pages/pages/noviac/ProfileSettings"));
const Properties = async(() => import("../pages/pages/noviac/properties/New"));
const PropertyDetails = async(() =>
  import("../pages/pages/noviac/properties/Details/index")
);
const Projects = async(() => import("../pages/pages/noviac/cruds/projects"));
const Notes = async(() => import("../pages/pages/noviac/cruds/notes"));

// const Tasks = async(() => import("../pages/pages/Tasks"));
// const Calendar = async(() => import("../pages/pages/Calendar"));
// const Chat = async(() => import("../pages/pages/Chat"));

const Changelog = async(() => import("../pages/docs/Changelog"));

// Landing
// const Landing = async(() => import("../pages/presentation/Landing"));

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

// For routes that can accessed by any authenticated users
export const admin_role = "ADMIN";
export const caller_role = "CALLER";
export const manager_role = "MANAGER";

const dashboardRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  icon: <Sliders />,
  component: Dashboard,
  roles: [admin_role],
  children: null,
  guard: AuthGuard,
};

const usersRoutes = {
  id: "Users",
  path: "/users",
  icon: <UsersIcon />,
  roles: [admin_role],
  children: [
    {
      path: "/users",
      name: "Users",
      component: Users,
      guard: AuthGuard,
      roles: [admin_role],
    },
    {
      path: "/users/add",
      name: "Add User",
      component: AddUser,
      guard: AuthGuard,
      roles: [admin_role],
    },
  ],
  component: null,
  guard: AuthGuard,
};

const crudRoutes = [
  {
    id: "Projects",
    path: "/project",
    icon: <BriefcaseIcon />,
    name: "Projects",
    component: Projects,
    guard: AuthGuard,
    roles: [admin_role],
  },
  {
    id: "Note",
    path: "/note",
    icon: <BookOpenIcon />,
    name: "Note",
    component: Notes,
    guard: AuthGuard,
    roles: [admin_role],
  },
];

const callsRoutes = {
  id: "Calls",
  path: "/calls",
  icon: <Phone />,
  roles: [admin_role, caller_role],
  children: [
    {
      path: "/calls",
      name: "Calls",
      component: Calls,
      guard: AuthGuard,
      roles: [admin_role, caller_role],
    },
    {
      path: "/calls/add",
      name: "Add Calls",
      component: AddCalls,
      guard: AuthGuard,
      roles: [admin_role],
    },
  ],
  component: null,
  guard: AuthGuard,
};

const profileRoutes = {
  id: "Profile",
  path: "/profile/:id?",
  icon: <User />,
  component: Profile,
  children: null,
  guard: AuthGuard,
};

const settingsRoutes = {
  id: "Profile Settings",
  path: "/settings",
  icon: <SettingsIcon />,
  component: Settings,
  children: null,
  guard: AuthGuard,
};

// const chatRoutes = {
//   id: "Chat",
//   path: "/chat",
//   icon: <MessageSquare />,
//   component: Chat,
//   children: null,
// };

const propertiesRoutes = {
  id: "Properties",
  path: "/properties",
  icon: <Domain />,
  component: Properties,
  children: null,
  guard: AuthGuard,
  roles: [manager_role, admin_role],
};
const propertyDetailsRoutes = {
  id: "Properties",
  path: "/properties/:id",
  icon: <Domain />,
  component: PropertyDetails,
  children: null,
  guard: AuthGuard,
  roles: [manager_role, admin_role],
};

const invoiceRoutes = {
  id: "Invoices",
  path: "/invoices",
  icon: <CreditCard />,
  children: [
    {
      path: "/invoices",
      name: "List",
      component: InvoiceList,
    },
    {
      path: "/invoices/detail",
      name: "Details",
      component: InvoiceDetails,
    },
  ],
  component: null,
};

// const tasksRoutes = {
//   id: "Tasks",
//   path: "/tasks",
//   icon: <CheckSquare />,
//   badge: "17",
//   component: Tasks,
//   children: null,
// };

// const calendarRoutes = {
//   id: "Calendar",
//   path: "/calendar",
//   icon: <CalendarIcon />,
//   component: Calendar,
//   children: null,
// };

const authRoutes = {
  id: "Auth",
  path: "/",
  icon: <UsersIcon />,
  children: [
    {
      path: "/sign-in",
      name: "Sign In",
      component: SignIn,
      guard: GuestGuard,
    },
    {
      path: "/reset-password",
      name: "Reset Password",
      component: ResetPassword,
      guard: GuestGuard,
    },
    {
      path: "/404",
      name: "404 Page",
      component: Page404,
      guard: GuestGuard,
    },
    {
      path: "/500",
      name: "500 Page",
      component: Page500,
      guard: GuestGuard,
    },
  ],
  component: null,
  guard: GuestGuard,
};

const formRoutes = {
  id: "Form",
  path: "/",
  icon: <Folder />,
  children: [
    {
      path: "/fiche-d-informations/:quoteToken",
      name: "Fiche d´Informations",
      component: FicheDInformations,
    },
  ],
  roles: [],
  component: null,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: () => (
    <GuestGuard>
      <Redirect to="/sign-in" />
    </GuestGuard>
  ),
  children: null,
};

const instructionsRoutes = {
  id: "Instructions",
  path: "/instructions",
  icon: <Info />,
  component: Changelog,
  children: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardRoutes,
  propertiesRoutes,
  propertyDetailsRoutes,
  callsRoutes,
  usersRoutes,
  ...crudRoutes,
  // chatRoutes,
  // invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  profileRoutes,
  settingsRoutes,
  //instructionsRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];
export const formLayoutRoutes = [formRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardRoutes,
  propertiesRoutes,
  { ...callsRoutes, roles: [caller_role], children: null },
  { ...callsRoutes, roles: [admin_role] },
  usersRoutes,
  ...crudRoutes,
  // chatRoutes,
  // invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  { ...profileRoutes, roles: [caller_role], path: "/profile" },
  settingsRoutes,
  //instructionsRoutes,
  // authRoutes,
];
