import React, { useMemo } from "react";
import ImagesSlider from "../../components/ImagesSlider";

export default function InfoViewImagesRenderer({
  values,
  touched,
  errors,
  setFieldValue,
  isEditing,
  handleBlur,
}) {
  const [imagesCanBeRemoved, removedImagesCanBeUnDo] = useMemo(() => {
    const imagesCanBeRemoved = [];
    const removedImagesCanBeUnDo = [];
    values.images.map((img) => {
      let image = {
        path: `/uploads/${img.filename}`,
        ...img,
      };
      if (values.del_images.includes(img.id))
        removedImagesCanBeUnDo.push(image);
      else imagesCanBeRemoved.push(image);
    });
    return [imagesCanBeRemoved, removedImagesCanBeUnDo];
  }, [values.images, values.del_images]);
  return (
    <>
      {!!imagesCanBeRemoved.length && (
        <>
          <b>Images</b>
          <ImagesSlider
            actions={
              isEditing && [
                {
                  icon: "delete",
                  handler: ({ id }) => {
                    console.log(id);
                    setFieldValue("del_images", values.del_images.concat(id));
                  },
                },
              ]
            }
            images={imagesCanBeRemoved}
          />
        </>
      )}
      {!!removedImagesCanBeUnDo.length && (
        <>
          <b>Removed images (You can still Undo)</b>
          <ImagesSlider
            actions={
              isEditing && [
                {
                  icon: "undo",
                  handler: ({ id }) => {
                    setFieldValue(
                      "del_images",
                      values.del_images.filter((did) => did != id)
                    );
                  },
                },
              ]
            }
            images={removedImagesCanBeUnDo}
          />
        </>
      )}
      {isEditing && (
        <>
          <b>Add New Images</b>
          <input
            type="file"
            placeholder="Add Images"
            multiple
            name="add_images"
            label="Add Images"
            error={Boolean(touched.add_images && errors.add_images)}
            helperText={touched.add_images && errors.add_images}
            onBlur={handleBlur}
            onChange={(event) => {
              setFieldValue("add_images", event.currentTarget.files);
            }}
          />
        </>
      )}
    </>
  );
}
