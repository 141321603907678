import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import callsReducer from "./callsReducer";
import propertyReducer from "./propertyReducer";
import employeesReducer from "./employeesReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  callsReducer,
  propertyReducer,
  employeesReducer,
});
