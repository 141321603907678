import * as types from "../../constants";
import {
  signIn as authSignIn,
  updateProfile as authUpdateProfile,
  addUser as authAddUser,
  resetPassword as authResetPassword,
  loggedInUser as authLoggedInUser,
  updateToken as authUpdateToken,
  removeToken as authRemoveToken,
} from "../../services/authService";

export function signIn(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    return authSignIn(credentials)
      .then(({ token, user }) => {
        authUpdateToken(token);
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          user,
        });
        return user;
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}
export function updateProfile(form) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_PROFILE_REQUEST });

    return authUpdateProfile(form)
      .then((user) => {
        dispatch({
          type: types.UPDATE_PROFILE_SUCCESS,
          user,
        });
      })
      .catch((error) => {
        dispatch({ type: types.UPDATE_PROFILE_FAILURE });
        throw error;
      });
  };
}

export function addUser(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_ADD_USER_REQUEST });

    return authAddUser(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_ADD_USER_SUCCESS,
          response,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_ADD_USER_FAILURE });
        throw error;
      });
  };
}

export function verifyToken() {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_LOAD_USER_REQUEST });
    const token = authUpdateToken();
    if (token)
      return authLoggedInUser()
        .then((user) => {
          dispatch({
            type: types.AUTH_LOAD_USER_SUCCESS,
            user,
          });
        })
        .catch((error) => {
          authRemoveToken();
          dispatch({ type: types.AUTH_LOAD_USER_FAILURE });
          throw error;
        });
    else dispatch({ type: types.AUTH_LOAD_USER_FAILURE });
  };
}

export function signOut() {
  return async (dispatch) => {
    authRemoveToken();
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}

export function resetPassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}
