import * as types from "../../constants";
import {
  ficheDInformations as propertyFicheDInformations,
  fetchProperties as propertyFetchProperties,
  createContact as propertyCreateContact,
  deleteContact as propertyDeleteContact,
  sendNewPropertyEmail as propertySendNewPropertyEmail,
  createProperty as propertyCreateProperty,
  deleteProperty as propertyDeleteProperty,
  updateProperty as propertyUpdateProperty,
  updatePropertyStatus as propertyUpdatePropertyStatus,
  sendContract as propertySendContract,
  sendMessageToContact as propertySendMessageToContact,
} from "../../services/propertyService";

export function ficheDInformations(form) {
  return async (dispatch) => {
    dispatch({ type: types.FICHE_D_INFORMATIONS_REQUEST });

    return propertyFicheDInformations(form)
      .then((response) => {
        dispatch({
          type: types.FICHE_D_INFORMATIONS_SUCCESS,
          response,
        });
        return response;
      })
      .catch((error) => {
        dispatch({ type: types.FICHE_D_INFORMATIONS_FAILURE });
        throw error;
      });
  };
}

export function fetchProperties() {
  return async (dispatch) => {
    dispatch({ type: types.PROPERTY_FETCH_REQUEST });

    return propertyFetchProperties()
      .then((properties) => {
        dispatch({
          type: types.PROPERTY_FETCH_SUCCESS,
          properties,
        });
        return properties;
      })
      .catch((error) => {
        dispatch({ type: types.PROPERTY_FETCH_FAILURE });
        throw error;
      });
  };
}

export function createContact(propertyId, contact) {
  return async (dispatch) => {
    return propertyCreateContact(propertyId, contact).then((contact) => {
      dispatch({
        type: types.CREATE_CONTACT_SUCCESS,
        contact,
        propertyId: contact.propertiesId || propertyId,
      });
      return contact;
    });
  };
}

export function deleteContact(propertyId, contactId) {
  return async (dispatch) => {
    return propertyDeleteContact(propertyId, contactId).then((contact) => {
      dispatch({
        type: types.DELETE_CONTACT_SUCCESS,
        propertyId,
        contactId,
      });
      return contact;
    });
  };
}

export function sendContract(propertyId, contract, email) {
  return async (dispatch) => {
    return propertySendContract(propertyId, contract, email).then(
      (response) => {
        console.log(response);
        return response;
      }
    );
  };
}

export function sendNewPropertyEmail(form) {
  return async (dispatch) => {
    return propertySendNewPropertyEmail(form).then((property) => {
      return property;
    });
  };
}
export function createProperty(form) {
  
  return async (dispatch) => {
    return propertyCreateProperty(form).then((property) => {
      dispatch({
        type: types.PROPERTY_CREATE_SUCCESS,
        property,
      });
      return property;
    });
  };
}

export function deleteProperty(id, history) {
  return (dispatch) => {
    return propertyDeleteProperty(id).then((response) => {
      history.goBack();
      dispatch({
        type: types.PROPERTY_DELETE_SUCCESS,
        propertyId: id,
      });
      return response;
    });
  };
}

export function updateProperty(propertyId, form) {
  return (dispatch) => {
    return propertyUpdateProperty(propertyId, form).then((property) => {
      dispatch({
        type: types.PROPERTY_UPDATE_SUCCESS,
        property,
        propertyId: property.id,
      });
      return property;
    });
  };
}
export function updatePropertyStatus(propertyId, status) {
  return (dispatch) => {
    return propertyUpdatePropertyStatus(propertyId, status).then((property) => {
      dispatch({
        type: types.PROPERTY_STATUS_UPDATE_SUCCESS,
        propertyId,
        status,
      });
      return property;
    });
  };
}

export function sendMessageToContact(propertyId, contactId) {
  return async (dispatch) => {
    return propertySendMessageToContact(propertyId, contactId).then(
      (respose) => {
        console.log(respose);
        dispatch({
          type: types.SENT_MESSAGE_SUCCESS,
          propertyId,
          contactId,
        });
        return respose;
      }
    );
  };
}
