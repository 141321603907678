import * as types from "../../constants";
import * as _ from "lodash";
const initialState = {
  loading: false,
  properties: [],
  error: null,
};
const sort = (array, column = "updatedAt", reverse = true) =>
  reverse ? _.sortBy(array, column).reverse() : _.sortBy(array, column);
export default function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case types.PROPERTY_FETCH_SUCCESS:
      return {
        ...state,
        properties: action.properties,
        loading: false,
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        ...initialState,
      };
    case types.PROPERTY_FETCH_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.PROPERTY_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case types.CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        properties: state.properties.map((p) =>
          p.id == action.propertyId
            ? {
                ...p,
                contacts: sort((p.contacts || []).concat(action.contact)),
              }
            : p
        ),
      };
    case types.DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        properties: state.properties.map((p) =>
          p.id == action.propertyId
            ? {
                ...p,
                contacts: sort(
                  (p.contacts || []).filter((c) => c.id != action.contactId)
                ),
              }
            : p
        ),
      };
    case types.SENT_MESSAGE_SUCCESS:
      return {
        ...state,
        properties: state.properties.map((p) =>
          p.id == action.propertyId
            ? {
                ...p,
                contacts: sort(
                  (p.contacts || []).map((c) =>
                    c.id == action.contactId
                      ? { ...c, is_transfered: true, updatedAt: new Date() }
                      : c
                  )
                ),
              }
            : p
        ),
      };

    case types.PROPERTY_UPDATE_SUCCESS:
      return {
        ...state,
        properties: state.properties.map((p) =>
          p.id == action.propertyId ? action.property : p
        ),
      };
    case types.PROPERTY_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        properties: state.properties.map((p) =>
          p.id == action.propertyId ? { ...p, status: action.status } : p
        ),
      };
    case types.PROPERTY_CREATE_SUCCESS:
      return {
        ...state,
        properties: state.properties.concat(action.property),
      };
    case types.PROPERTY_DELETE_SUCCESS:
      return {
        ...state,
        properties: state.properties.filter(
          ({ id }) => action.propertyId != id
        ),
      };

    default:
      return state;
  }
}
