import axios from "../utils/axios";
import jsonToData from "json-form-data";

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/sign-in", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function changePassword(data) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/change-password", data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateProfile(form) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/update-profile", jsonToData(form))
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function addUser(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/add-user", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function loggedInUser() {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/auth/loggedin-user")
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/forgot-password", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateToken(token) {
  if (!token) token = localStorage.getItem("token");
  if (token) {
    localStorage.setItem("token", token);
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    removeToken();
  }
  return token;
}

export function removeToken() {
  localStorage.removeItem("token");
  axios.defaults.headers.common["Authorization"] = null;
}
