import axios from "axios";

let baseURL;
if (process.env.REACT_APP_ENV === 'development') {
  baseURL = "http://localhost:3000"
} else {
  baseURL = 'https://noviac.ch'
}

export default axios.create({
  baseURL: baseURL,
  validateStatus: (status) => status < 500,
});
// console.log(localStorage.getItem("token"));
