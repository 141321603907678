import * as types from "../../constants";
const defaultState = {
  myCalls: [],
  feedbackCalls: [],
  loading: false,
};
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.FETCH_MY_CALLS_SUCCESS:
      return {
        ...state,
        myCalls: action.myCalls,
        feedbackCalls: action.feedbackCalls,
        loading: false,
      };

    case types.FETCH_MY_CALLS_FAILURE:
      return {
        ...state,
        loading: false,
        myCalls: [],
      };

    case types.UPDATE_A_CALLS_SUCCESS:
      return {
        ...state,
        myCalls: state.myCalls.map((call) =>
          call.id == action.callId ? { ...call, ...action.updates } : call
        ),
      };

    case types.ADD_A_CALL_SUCCESS:
      return {
        ...state,
        myCalls: [action.payload.call, ...state.myCalls],
      };

    case types.FETCH_MY_CALLS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
