import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { verifyToken } from "../redux/actions/authActions";

// For routes that can only be accessed by authenticated users
function LoggedinUserProvider({ children }) {
  const auth = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(verifyToken());
  }, [dispatch]);

  useEffect(() => {
    // console.log(auth.user);
  }, [auth?.user]);
  if (auth.initial_loading) {
    return <Loader />;
  }

  return children;
}

export default LoggedinUserProvider;
