import React, { useMemo, useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { DateFormate } from "./DateFormate";

// import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
// import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from "@material-ui/core/AppBar";
// import Toolbar from '@material-ui/core/Toolbar';
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import LanguageIcon from "@material-ui/icons/Language";
import en_common from "../Translations/en/common.json";
import de_common from "../Translations/de/common.json";
import fr_common from "../Translations/fr/common.json";
import { ficheDInformations } from "../../redux/actions/propertyActions";
import { useDispatch } from "react-redux";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";

import {
  getDefaultFicheDInformations,
  ValidateFicheDInformations,
} from "../../utils/validation";
import { FrenchValidateFicheDInformations } from "../../utils/fr_validation";
import { GermanValidateFicheDInformations } from "../../utils/de_validation";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import PhoneInputWithCountry from "react-phone-number-input";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import "./phoneNo.css";

import {
  Toolbar,
  CssBaseline,
  Checkbox,
  FormControlLabel,
  Button,
  Container,
  TextField as MuiTextField,
  Grid,
  Switch,
  Select,
  Menu,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Formik, Field, Form } from "formik";
import { Alert } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";

import InfoViewImagesRenderer from "./InfoViewImagesRenderer";
import { cantons } from "../../constants";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const TextField = styled(MuiTextField)(spacing);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 850,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(9),
    marginLeft: theme.spacing(1),
  },
  textfieldClass: {
    "& .MuiInput-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
  },
}));

export const PropertyForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  infoView,
  isEditing,
  isCreatingByAdmin,
  activeStep,
  setActiveStep,
  hideTitle,
}) => {
  const [language, setLanguage] = useState(fr_common);
  const [currentErrorLanguage, setCurrentErrorLanguage] = useState(
    FrenchValidateFicheDInformations
  );

  formatPhoneNumberIntl("+12133734253" === "+1 213 373 4253");
  const classes = useStyles();

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleLanguageChange = (e) => {
    switch (e.target.value) {
      case 1:
        setLanguage(en_common);
        setCurrentErrorLanguage(ValidateFicheDInformations);
        break;
      case 2:
        setLanguage(fr_common);
        setCurrentErrorLanguage(FrenchValidateFicheDInformations);
        break;
      case 3:
        setLanguage(de_common);
        setCurrentErrorLanguage(GermanValidateFicheDInformations);
        break;
    }
  };

  const steps = [language.personalInfo_label, language.info_label];

  // const isLastStep = activeStep === steps.length - 1;

  const formRef = useRef();

  useEffect(() => {}, [language]);

  const min = -2;
  const max = 12;

  const handlePhoneInput = (value, onChange) => {
    if (value === undefined) {
      onChange("client_phone")("");
    } else {
      onChange("client_phone")(value);
    }
  };

  document.addEventListener("wheel", (event) => {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });

  const handleValidation = () => {
    currentErrorLanguage[activeStep].validate().then((result) => {
      setActiveStep(activeStep + 1);
    });
  };

  // for date
  // const [contractEndDate, setcontractEndDate] = useState("");
  // useEffect(() => {
  //   console.log("datevalue", contractEndDate);
  // }, [contractEndDate]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Typography
          component="div"
          style={{ height: "5vh", textAlign: "right" }}
        >
          <LanguageIcon style={{ marginTop: "0.4rem" }} />
          <FormControl
            variant=""
            className={classes.formControl}
            style={{ paddingLeft: "0.5rem" }}
          >
            {/* <InputLabel id="demo-simple-select-outlined-label">Select Language</InputLabel> */}
            <Select
              defaultValue={2}
              labelId="languageDropdown"
              id="languageDropdown"
              onChange={handleLanguageChange}
              label="Age"
            >
              <MenuItem value={1} selected>
                English
              </MenuItem>
              <MenuItem value={2}>French</MenuItem>
              <MenuItem value={3}>German</MenuItem>
            </Select>
          </FormControl>
        </Typography>
      </Container>

      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{ textAlign: "center" }}
                >
                  {isEditing
                    ? "Please wait, your property is updating.."
                    : "Please wait, your property is creating.."}
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Formik
                  initialValues={initialValues}
                  validationSchema={currentErrorLanguage[activeStep]}
                  onSubmit={onSubmit}
                  innerRef={formRef}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <TextField
                        type="hidden"
                        name="active_step"
                        value={activeStep}
                      />
                      {activeStep == 0 ? (
                        <div className="">
                          <Grid container spacing={6}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="text"
                                name="client_name"
                                label={language.first_name_label}
                                // label="Address"
                                value={values.client_name}
                                error={Boolean(
                                  touched.client_name && errors.client_name
                                )}
                                fullWidth
                                helperText={
                                  touched.client_name && errors.client_name
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="text"
                                name="client_surname"
                                label={language.last_name_label}
                                value={values.client_surname}
                                error={Boolean(
                                  touched.client_surname &&
                                    errors.client_surname
                                )}
                                fullWidth
                                helperText={
                                  touched.client_surname &&
                                  errors.client_surname
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                type="text"
                                name="client_address"
                                label={language.current_address_label}
                                value={values.client_address}
                                error={Boolean(
                                  touched.client_address &&
                                    errors.client_address
                                )}
                                fullWidth
                                helperText={
                                  touched.client_address &&
                                  errors.client_address
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="text"
                                name="client_npa"
                                label={language.postcode_label}
                                value={values.client_npa}
                                error={Boolean(
                                  touched.client_npa && errors.client_npa
                                )}
                                fullWidth
                                helperText={
                                  touched.client_npa && errors.client_npa
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="text"
                                name="client_city"
                                label={language.locality_label}
                                value={values.client_city}
                                error={Boolean(
                                  touched.client_city && errors.client_city
                                )}
                                fullWidth
                                helperText={
                                  touched.client_city && errors.client_city
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{ outline: "none" }}
                            >
                              {/* <TextField
                                className={classes.textfieldClass}
                                type="number"
                                name="client_phone"
                                label={language.mobile_number_label}
                                value={values.client_phone}
                                error={Boolean(
                                  touched.client_phone && errors.client_phone
                                )}
                                {...props}
                                InputProps={{
                                  className: classes.input,
                                }}
                                inputRef={ref}
                                fullWidth
                                flags={flags}
                                helperText={
                                  touched.client_phone && errors.client_phone
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              /> */}
                              <div
                                className="phone-title"
                                style={{
                                  paddingTop: "10px",
                                  color: "rgba(0, 0, 0, 0.54)",
                                  transition:
                                    "cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                                  fontSize: "11px",
                                  fontFamily:
                                    "Inter,-apple-system,BlinkMacSystemFont",
                                  fontWeight: "400",
                                  lineHeight: "1",
                                }}
                              >
                                {language.mobile_number_label}
                              </div>
                              <PhoneInput
                                international
                                label={language.mobile_number_label}
                                name="client_phone"
                                type="tel"
                                className="countryCode"
                                placeholder="Enter phone number"
                                defaultCountry="CH"
                                value={values.client_phone}
                                helperText={
                                  touched.client_phone && errors.client_phone
                                }
                                error={Boolean(
                                  touched.client_phone && errors.client_phone
                                )}
                                // onBlur={handleBlur}
                                onChange={(value) =>
                                  handlePhoneInput(value, handleChange)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="text"
                                name="client_mail"
                                label={language.email_label}
                                value={values.client_mail.trim()}
                                error={Boolean(
                                  touched.client_mail && errors.client_mail
                                )}
                                fullWidth
                                helperText={
                                  touched.client_mail && errors.client_mail
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      ) : (
                        // ( activeStep == 0

                        <div>
                          <Grid container spacing={3}>
                            {hideTitle == "Please hide the title" ? (
                              // <TextField
                              //   fullWidth
                              //   type="text"
                              //   // name="title"
                              //   label={language.title_label}
                              //   // label="Veuillez entrer le titre"
                              //   value={values.title}
                              //   inputProps={{ readOnly: true }}
                              //   my={2}
                              // />
                              ""
                            ) : (
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  type="text"
                                  name="title"
                                  label={language.title_label}
                                  value={values.title}
                                  error={Boolean(touched.title && errors.title)}
                                  helperText={touched.title && errors.title}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  disabled={infoView && !isEditing}
                                  my={2}
                                />
                              </Grid>
                            )}

                            {hideTitle == "Please hide the title" ? (
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  style={{
                                    position: "relative",
                                    marginRight: "50px",
                                  }}
                                  type="text"
                                  name="prop_address"
                                  label={language.address_for_rent_label}
                                  value={values.prop_address}
                                  error={Boolean(
                                    touched.prop_address && errors.prop_address
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.prop_address && errors.prop_address
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  disabled={infoView && !isEditing}
                                  my={2}
                                />
                              </Grid>
                            ) : (
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  type="text"
                                  name="prop_address"
                                  label={language.address_for_rent_label}
                                  value={values.prop_address}
                                  error={Boolean(
                                    touched.prop_address && errors.prop_address
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.prop_address && errors.prop_address
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  disabled={infoView && !isEditing}
                                  my={2}
                                />
                              </Grid>
                            )}

                            {isCreatingByAdmin && (
                              <>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    type="number"
                                    name="quote"
                                    label="Quote"
                                    fullWidth
                                    value={values.quote}
                                    error={Boolean(
                                      touched.quote && errors.quote
                                    )}
                                    helperText={touched.quote && errors.quote}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={infoView && !isEditing}
                                    my={2}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  container
                                  alignItems="center"
                                >
                                  <Grid item>For Tenant</Grid>
                                  <Grid item>
                                    <Switch
                                      name="is_landlord"
                                      checked={values.is_landlord}
                                      error={Boolean(
                                        touched.is_landlord &&
                                          errors.is_landlord
                                      )}
                                      helperText={
                                        touched.is_landlord &&
                                        errors.is_landlord
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      disabled={infoView && !isEditing}
                                    />
                                  </Grid>
                                  <Grid item>For Landlord</Grid>
                                </Grid>
                              </>
                            )}
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="text"
                                name="prop_npa"
                                label={language.rent_postcode_label}
                                value={values.prop_npa}
                                error={Boolean(
                                  touched.prop_npa && errors.prop_npa
                                )}
                                fullWidth
                                helperText={touched.prop_npa && errors.prop_npa}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="text"
                                name="prop_city"
                                label={language.rent_locality_label}
                                value={values.prop_city}
                                error={Boolean(
                                  touched.prop_city && errors.prop_city
                                )}
                                fullWidth
                                helperText={
                                  touched.prop_city && errors.prop_city
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                select
                                type="text"
                                name="prop_canton"
                                label={language.canton_label}
                                value={values.prop_canton}
                                error={Boolean(
                                  touched.prop_canton && errors.prop_canton
                                )}
                                fullWidth
                                helperText={
                                  touched.prop_canton && errors.prop_canton
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              >
                                {cantons.map((canton) => (
                                  <MenuItem value={canton}>{canton}</MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                component={DateFormate}
                                setDateValue={handleChange}
                                name="contract_start"
                                label={language.available_from_label}
                                value={values.contract_start}
                                error={Boolean(
                                  touched.contract_start &&
                                    errors.contract_start
                                )}
                                fullWidth
                                helperText={
                                  touched.contract_start &&
                                  errors.contract_start
                                }
                                onBlur={handleBlur}
                                disabled={infoView && !isEditing}
                                my={2}
                                placeholder="dd/mm/yyyy"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                component={DateFormate}
                                setDateValue={handleChange}
                                name="contract_end"
                                label={language.lease_ends_on_label}
                                value={values.contract_end}
                                error={Boolean(
                                  touched.contract_end && errors.contract_end
                                )}
                                fullWidth
                                helperText={
                                  touched.contract_end && errors.contract_end
                                }
                                onBlur={handleBlur}
                                disabled={infoView && !isEditing}
                                my={2}
                                placeholder="dd/mm/yyyy"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="number"
                                name="net_rent"
                                label={language.net_rent_label}
                                value={values.net_rent}
                                error={Boolean(
                                  touched.net_rent && errors.net_rent
                                )}
                                fullWidth
                                helperText={touched.net_rent && errors.net_rent}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="number"
                                name="fees"
                                label={language.utilities_label}
                                value={values.fees}
                                error={Boolean(touched.fees && errors.fees)}
                                fullWidth
                                helperText={touched.fees && errors.fees}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="number"
                                name="management"
                                label={language.living_space_label}
                                value={values.management}
                                error={Boolean(
                                  touched.management && errors.management
                                )}
                                fullWidth
                                helperText={
                                  touched.management && errors.management
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="number"
                                name="rooms"
                                label={language.number_of_rooms_label}
                                value={values.rooms}
                                error={Boolean(touched.rooms && errors.rooms)}
                                fullWidth
                                helperText={touched.rooms && errors.rooms}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="number"
                                name="bedrooms"
                                label={language.number_of_bedrooms_label}
                                value={values.bedrooms}
                                error={Boolean(
                                  touched.bedrooms && errors.bedrooms
                                )}
                                fullWidth
                                helperText={touched.bedrooms && errors.bedrooms}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="number"
                                name="washrooms"
                                label={language.number_of_shower_label}
                                // label="Nombre de salle de bain/salle de douches/WC"
                                value={values.washrooms}
                                error={Boolean(
                                  touched.washrooms && errors.washrooms
                                )}
                                fullWidth
                                helperText={
                                  touched.washrooms && errors.washrooms
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                component={DateFormate}
                                setDateValue={handleChange}
                                name="availibility"
                                label={language.year_construction_label}
                                value={values.availibility}
                                error={Boolean(
                                  touched.availibility && errors.availibility
                                )}
                                fullWidth
                                helperText={
                                  touched.availibility && errors.availibility
                                }
                                onBlur={handleBlur}
                                disabled={infoView && !isEditing}
                                my={2}
                                placeholder="dd/mm/yyyy"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                id="rank"
                                type="number"
                                name="floor"
                                inputProps={{ min, max }}
                                label={language.floor_label}
                                value={values.floor}
                                error={Boolean(touched.floor && errors.floor)}
                                fullWidth
                                helperText={touched.floor && errors.floor}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="number"
                                name="floors_in_building"
                                label={language.number_floor_label}
                                value={values.floors_in_building}
                                error={Boolean(
                                  touched.floors_in_building &&
                                    errors.floors_in_building
                                )}
                                fullWidth
                                helperText={
                                  touched.floors_in_building &&
                                  errors.floors_in_building
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="text"
                                name="view"
                                label="Vue"
                                value={values.view}
                                error={Boolean(touched.view && errors.view)}
                                fullWidth
                                helperText={touched.view && errors.view}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}></Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.car_outdoor}
                                    name="car_outdoor"
                                    onChange={handleChange}
                                    disabled={infoView && !isEditing}
                                    color="primary"
                                  />
                                }
                                label="Place de parc"
                                label={language.parking_place_label}
                              />
                              {values.car_outdoor && (
                                <TextField
                                  type="number"
                                  name="car_outdoor_price"
                                  label={language.price_label}
                                  value={values.car_outdoor_price}
                                  error={Boolean(
                                    touched.car_outdoor_price &&
                                      errors.car_outdoor_price
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.car_outdoor_price &&
                                    errors.car_outdoor_price
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  disabled={infoView && !isEditing}
                                  my={2}
                                />
                              )}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{ display: "flex", alignItems: "center" }}
                              sm={6}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.car_indoor}
                                    name="car_indoor"
                                    onChange={handleChange}
                                    disabled={infoView && !isEditing}
                                    color="primary"
                                  />
                                }
                                label={language.garage_label}
                              />
                              {values.car_indoor && (
                                <TextField
                                  type="number"
                                  name="car_indoor_price"
                                  label={language.price_label}
                                  value={values.car_indoor_price}
                                  error={Boolean(
                                    touched.car_indoor_price &&
                                      errors.car_indoor_price
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.car_indoor_price &&
                                    errors.car_indoor_price
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  disabled={infoView && !isEditing}
                                  my={2}
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.balcony}
                                    name="balcony"
                                    onChange={handleChange}
                                    disabled={infoView && !isEditing}
                                    color="primary"
                                  />
                                }
                                label={language.balcony_label}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.garden}
                                    name="garden"
                                    onChange={handleChange}
                                    disabled={infoView && !isEditing}
                                    color="primary"
                                  />
                                }
                                label={language.garden_label}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.washing}
                                    name="washing"
                                    onChange={handleChange}
                                    disabled={infoView && !isEditing}
                                    color="primary"
                                  />
                                }
                                label={language.washing_label}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.lift}
                                    name="lift"
                                    onChange={handleChange}
                                    disabled={infoView && !isEditing}
                                    color="primary"
                                  />
                                }
                                label={language.elevator_label}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                type="text"
                                name="client_note"
                                label={language.other_label}
                                value={values.client_note}
                                error={Boolean(
                                  touched.client_note && errors.client_note
                                )}
                                fullWidth
                                helperText={
                                  touched.client_note && errors.client_note
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                type="text"
                                name="description"
                                label={language.description_label}
                                multiline
                                rows={3}
                                value={values.description}
                                error={Boolean(
                                  touched.description && errors.description
                                )}
                                fullWidth
                                helperText={
                                  touched.description && errors.description
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={infoView && !isEditing}
                                my={2}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              {!infoView ? (
                                <input
                                  type="file"
                                  placeholder="Images"
                                  multiple
                                  name="images"
                                  label={language.create_my_folder_now_label}
                                  error={Boolean(
                                    touched.images && errors.images
                                  )}
                                  helperText={touched.images && errors.images}
                                  onBlur={handleBlur}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "images",
                                      event.currentTarget.files
                                    );
                                  }}
                                />
                              ) : (
                                <InfoViewImagesRenderer
                                  isEditing={isEditing}
                                  values={values}
                                  touched={touched}
                                  errors={errors}
                                  handleBlur={handleBlur}
                                  setFieldValue={setFieldValue}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </div>
                        // )
                      )}
                      <div className={classes.buttons}>
                        {activeStep !== 0 && (
                          <Button
                            type="button"
                            onClick={handleBack}
                            className={classes.button}
                          >
                            {language.back_btn}
                          </Button>
                        )}
                        <div className={classes.wrapper}>
                          {activeStep == 0 ? (
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              onClick={(e) => {
                                handleValidation();
                                setFieldValue("active_step", activeStep + 1);
                              }}
                            >
                              {language.next_btn}
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              disabled={isSubmitting}
                              onClick={(e) => {
                                // setActiveStep(activeStep + 1);
                                setFieldValue("active_step", activeStep + 1);
                              }}
                            >
                              {language.add_property_btn}
                            </Button>
                          )}
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        {/* <Copyright /> */}
      </main>
    </React.Fragment>
  );
};
