import { useCallback, useEffect, useState } from "react";
import { useSnackBarAlert } from "../providers/SnackBarProvider";

export const useService = (service, fetchOnMount = true) => {
  const [loading, setLoading] = useState(fetchOnMount);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const snackbarAlert = useSnackBarAlert();

  const onCatch = (e) => {
    snackbarAlert(
      `Error: ${e.response?.data?.message || e.message}`,
      "warning"
    );
    console.error(e);
    setError(e);
    setLoading(false);
    return Promise.reject(e);
  };

  const execute = useCallback(
    (...args) => {
      setLoading(true);

      return service(...args)
        .then((data) => {
          console.log(data);
          data.__args = args;
          setData(data);
          setLoading(false);
        })
        .catch(onCatch);
    },
    [onCatch]
  );

  useEffect(() => {
    if (fetchOnMount) execute();
  }, []);

  return {
    loading,
    data,
    setData,
    error,
    execute,
  };
};
