import * as types from "../../constants";

export default function reducer(
  state = {
    initial_loading: true,
  },
  action
) {
  switch (action.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
    case types.AUTH_LOAD_USER_SUCCESS:
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.user,
        loading: false,
        initial_loading: false,
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        loading: false,
        initial_loading: false,
        user: undefined,
      };
    case types.AUTH_LOAD_USER_FAILURE:
    case types.AUTH_SIGN_IN_FAILURE:
    case types.AUTH_ADD_USER_FAILURE:
    case types.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        initial_loading: false,
      };
    case types.AUTH_ADD_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.AUTH_SIGN_IN_REQUEST:
    case types.AUTH_LOAD_USER_REQUEST:
      return {
        ...state,
        loading: true,
        user: null,
      };
    case types.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
