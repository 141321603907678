import * as types from "../../constants";
const defaultState = {
  employees: [],
  loading: false,
};
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.EMPLOYEES_FETCH_SUCCESS:
      return {
        ...state,
        employees: action.employees,
        loading: false,
      };

    case types.EMPLOYEES_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        employees: [],
      };

    case types.EMPLOYEES_FETCH_REQUEST:
    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        employees: state.employees.map((u) =>
          u.id == action.user.id ? action.user : u
        ),
        loading: false,
      };
    default:
      return state;
  }
}
