import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { render } from "react-dom";

// // graphql dependencies
// import { setContext } from "apollo-link-context";
// import { ApolloProvider } from "@apollo/react-hooks";
// import { ApolloClient } from "apollo-client";
// import { InMemoryCache } from "apollo-cache-inmemory";
// import { createUploadLink } from "apollo-upload-client";

import App from "./App";

import { Provider } from "react-redux";
import store from "./redux/store/index";

// const link = setContext((_, { headers }) => {
//   const token = localStorage.getItem("token");
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     },
//   };
// });

// const client = new ApolloClient({
//   link: link.concat(createUploadLink({ uri: "/graphql" })),
//   cache: new InMemoryCache(),
//   defaultOptions: {
//     watchQuery: {
//       fetchPolicy: 'no-cache'
//     },
//     query: {
//       fetchPolicy: 'no-cache'
//     },
//     mutate: {
//       fetchPolicy: 'no-cache'
//     }
//   }
// });

render(
  // <ApolloProvider client={client}>
  <Provider store={store}>
    <App />
  </Provider>,
  // </ApolloProvider>,
  document.querySelector("#root")
);
