import * as Yup from "yup";
import moment from "moment";
import { cantons } from "../constants";

export const getDefaultFicheDInformations = (quoteToken) =>
  Object.assign(
    {
      active_step:0,
      // Informations personnelles
      client_name: "", // NOM
      client_surname: "", // Prénom
      client_address: "", // Adresse actualle
      client_npa: "", // NPA
      client_city: "", // Localité
      client_phone: "", // Téléphone portable
      client_mail: "", // Mail
      // Informations sur le bien à louer
      title: "", // Titre du bien à louer
      prop_address: "", // Adresse du bien á louer
      prop_npa: "", // NPA
      prop_city: "", // Localité
      prop_canton: "Vaud", // Canton
      contract_start: moment().format("yyyy-MM-DD"), // Libre dés le
      contract_end: moment().format("yyyy-MM-DD"), // Fin du bail le
      net_rent: 0, // Loyer net
      fees: 0, // Charges
      management: "", // Surface habitable
      rooms: 0, // Nombre de piéces
      bedrooms: 0, // Nombre de chambres à coucher
      washrooms: 0, // Nombre de salle de bain/salle de douches/WC (ex : 1 salle de bain WC et 1 WC séparé)
      availibility: moment().format("yyyy-MM-DD"), // Année de construction et/ou de rénoovation
      // floor: 0, // Étage
      // floors_in_building: 0, // Nbre étage båtiment
      view: "", // Vue
      car_outdoor: false, // Place de parc
      car_outdoor_price: 0, // Prix
      car_indoor: false, // Garage
      car_indoor_price: 0, // Prix
      balcony: false, // Balcon
      garden: false, // Jardin
      washing: false, // Machine à laver
      lift: false, // Ascenseur
      client_note: "", // Autre
      description: "", // Autre
      // quote: 0, // The amount of the quote in the email
      // status: progress, // We will set this up later but it is if it is in progress, success or failed
      // billing: new Date(), // Date on which the status is changed from in progress to success or failed
      // paid: false, // we'll set this up later
      images: [], // as i mentioned in requirements we should be able to upload several images
    },
    typeof quoteToken == "string"
      ? { quoteToken }
      : {
          quote: 0,
          is_landlord: false,
        }
  );

export const getValidateFicheDInformations = (isCreatingByAdmin = false) =>
  [
    Yup.object().shape(
      Object.assign(
        {
          active_step: Yup.string(),
          // Informations personnelles
          client_name: Yup.string()
            .required("Please enter your first name"),
            // .matches(/^[aA-zZ\s]+$/, "First name should contain only alphabets"), // NOM
          // client_name: Yup.string().required("Merci d'indiquer votre nom"), // NOM
          client_surname: Yup.string(),
            // .required("Please enter your last names")
            // .matches(/^[aA-zZ\s]+$/, "Last name should contain only alphabets"), // Prénom
          client_address: Yup.string(), // Adresse actualle
          client_npa: Yup.string().matches(
            /^[A-Za-z0-9 ]+$/,
            "Postcode can not have special characters"
          ), // NPA
          client_city: Yup.string(), // Localité
          client_phone: Yup.string()
            .required("Please enter your phone number"),
            // .matches(
            //   /^[A-Za-z0-9 ]+$/,
            //   "Phone number can not have special characters"
            // ), 
          client_mail: Yup.string().email("Invalid email address format"), // Mail
        },
        isCreatingByAdmin
          ? {
              quote: Yup.number().integer().required("Le Quote est requis"),
              is_landlord: Yup.boolean(),
            }
          : {}
      )
    ),
    Yup.object().shape(
      Object.assign(
        {
          // Informations sur le bien à louer
          title: Yup.string(), // Titre du bien à louer
          prop_address: Yup.string(), // Adresse du bien á louer
          prop_npa: Yup.string().matches(
            /^[A-Za-z0-9 ]+$/,
            "Postcode can not have special characters"
          ), // NPA
          prop_city: Yup.string(), // Localité
          prop_canton: Yup.string()
            .oneOf(cantons)
            .required("Merci d'indiquer le canton"), // Canton
          contract_start: Yup.date(),
          // .min(new Date(), "Libre de doit être plus tard qu'aujourd'hui")
          // .max(Yup.ref("contract_end"), "libre de doit être avant la fin du bail"), // Libre dés le
          contract_end: Yup.date().nullable(true), // Fin du bail le
          net_rent: Yup.number(), // Loyer net
          fees: Yup.number(), // Charges
          management: Yup.string().nullable(true), // Surface habitable
          rooms: Yup.number(), // Nombre de piéces
          bedrooms: Yup.number(), // Nombre de chambres à coucher
          washrooms: Yup.number(), // Nombre de salle de bain/salle de douches/WC (ex : 1 salle de bain WC et 1 WC séparé)
          availibility: Yup.date().nullable(true), // Année de construction et/ou de rénoovation
          floor: Yup.number().integer().nullable(true), // Étage
          floors_in_building: Yup.number().integer(), // Nbre étage båtiment
          view: Yup.string().nullable(true), // Vue
          car_outdoor: Yup.boolean().nullable(true), // Place de parc
          car_outdoor_price: Yup.number().nullable(true), // Prix
          car_indoor: Yup.boolean().nullable(true), // Garage
          car_indoor_price: Yup.number().nullable(true), // Prix
          balcony: Yup.boolean(), // Balcon
          garden: Yup.boolean(), // Jardin
          washing: Yup.boolean(), // Machine à laver
          lift: Yup.boolean(), // Ascenseur
          client_note: Yup.string().nullable(true), // Autre
          description: Yup.string().nullable(true), // Description
        },
        isCreatingByAdmin
          ? {
              quote: Yup.number().integer().required("Le Quote est requis"),
              is_landlord: Yup.boolean(),
            }
          : {}
      )
    )
  ];
export const ValidateFicheDInformations = getValidateFicheDInformations();

export const getDefaultAddCalls = () => ({
  csv: null,
  employeeId: "",
});

export const ValidateAddCalls = Yup.object().shape({
  csv: Yup.object()
    .nullable(true)
    .shape({
      name: Yup.string().required("Le CSV est requis"),
    }),
  employeeId: Yup.string().required("Le Employee est requis"),
});

export const getCrudInitialValues = (entity) => ({
  title: entity?.title || "",
  content: entity?.content || "",
  image: null,
  attachments: [],
});

export const getCrudValidations = (entity) => {
  Yup.object().shape({
    title: Yup.string().required("Le Title est requis"),
    content: Yup.string().required("Le Content est requis"),
    image: Yup.object()
      .nullable(true)
      .shape({
        name: Yup.string().required("Le Image est requis"),
      }),
    attachments: Yup.array().of(
      Yup.object()
        .nullable(true)
        .shape({
          name: Yup.string().required("Le Attachments est requis"),
        })
    ),
  });
};
