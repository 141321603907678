import { InputLabel } from "@material-ui/core";
import React, { Component } from "react";
import { DropdownDate, DropdownComponent } from "react-dropdown-date";
import "./phoneNo.css";

const formatDate = (date) => {
  // formats a JS date to 'yyyy-mm-dd'
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export class DateFormate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      selectedDate: props.value,
    };
  }

  render() {
    return (
      <div className="dateformator">
        <InputLabel>{this.props.label}</InputLabel>
        <div style={{ display: "flex" }}>
          <DropdownDate
            style={{ display: "flex", color: "red" }}
            startDate={"1980-01-01"}
            endDate={"2055-12-31"}
            selectedDate={this.state.selectedDate}
            order={[
              DropdownComponent.day,
              DropdownComponent.month,
              DropdownComponent.year,
            ]}
            onDateChange={(date) => {
              this.setState({ date: date, selectedDate: formatDate(date) });
              date === undefined && this.props.name === "contract_end"
                ? console.log(date)
                : this.props.setDateValue(this.props.field.name)(
                    formatDate(date)
                  );
            }}
            // onDateChange={(date) => {
            //   this.setState({ date: date, selectedDate: formatDate(date) });
            // }}
          />
        </div>
      </div>
    );
  }
}
