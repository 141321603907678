import axios from "../utils/axios";
import * as jsonToData from "json-form-data";

export function validateFicheDInformationsToken(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/properties/validate-token/${token}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function ficheDInformations(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "/api/properties",
        jsonToData(
          { ...data, images: Object.values(data.images) },
          {
            initialFormData: new FormData(),
            showLeafArrayIndexes: false,
            mapping: (value) => value,
          }
        )
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error?.response?.data || error);
      });
  });
}
export function fetchProperties() {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/properties")
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function countPendingProperties(status = "pending") {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/properties/count/${status}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function createContact(propertyId, contact) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/properties/${propertyId}/contact`, contact)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteContact(propertyId, contactId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/properties/${propertyId}/contact/${contactId}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function sendMessageToContact(propertyId, contactId) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/properties/${propertyId}/contact/${contactId}/message`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sendContract(propertyId, contract, email) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/api/properties/${propertyId}/contract`,
        jsonToData(
          {
            email,
            contract: contract,
          },
          {
            initialFormData: new FormData(),
            showLeafArrayIndexes: false,
            mapping: (value) => value,
          }
        )
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePropertyStatus(propertyId, status) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/properties/${propertyId}/status/${status}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateProperty(propertyId, form) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `/api/properties/${propertyId}`,
        jsonToData(
          { ...form, add_images: Object.values(form.add_images) },
          {
            initialFormData: new FormData(),
            showLeafArrayIndexes: false,
            mapping: (value) => value,
          }
        )
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sendNewPropertyEmail(form) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/api/properties/send/new-email/property`,
        jsonToData(
          { ...form, images: Object.values(form.images) },
          {
            initialFormData: new FormData(),
            showLeafArrayIndexes: false,
            mapping: (value) => value,
          }
        )
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error?.response?.data || error);
      });
  });
}
export function createProperty(form) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/api/properties`,
        jsonToData(
          { ...form, images: Object.values(form.images) },
          {
            initialFormData: new FormData(),
            showLeafArrayIndexes: false,
            mapping: (value) => value,
          }
        )
      )
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error?.response?.data || error);
      });
  });
}

export function deleteProperty(id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/api/properties/${id}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
